import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

export default function ClientLogos() {
    return (
      <div>
        <div className="mx-auto py-12 xl:py-14">
          <div className="grid grid-cols-2 gap-8 justify-between sm:grid-cols-3 xl:grid-cols-4">
            <div className="col-span-1 flex items-center justify-between lg:col-span-1">
              <StaticImage objectFit="contain" src='../images/client-logos/sergio-tacchini.png' className="h-6 md:h-7" alt='Sergio Tacchini' />
            </div>
            <div className="col-span-1 flex items-center justify-between lg:col-span-1">
              <StaticImage objectFit="contain" src='../images/client-logos/marville.png' className="h-10" alt='Marville' />
            </div>
            <div className="col-span-1 flex items-center justify-between lg:col-span-1">
              <StaticImage objectFit="contain" src='../images/client-logos/us-polo.png' className="h-10" alt='US Polo Assn.' />
            </div>
            <div className="col-span-1 items-center flex justify-between lg:col-span-1">
              <StaticImage objectFit="contain" src='../images/client-logos/liu-jo.png' className="h-6" alt='Liu Jo' />
            </div>
            <div className="col-span-1 flex items-center justify-between lg:col-span-1">
              <StaticImage objectFit="contain" src='../images/client-logos/cafe-noir.png' className="h-4" alt='Cafe Noir'  />
            </div>
            <div className="col-span-1 flex items-center justify-between lg:col-span-1">
              <StaticImage objectFit="contain" src='../images/client-logos/slam.png' className="h-9" alt='SLAM'/>
            </div>
            <div className="col-span-1 flex items-center justify-between lg:col-span-1">
              <StaticImage objectFit="contain" src='../images/client-logos/peuterey.png' className="h-10" alt='Peuterey'  />
            </div>
            <div className="col-span-1 flex items-center justify-between lg:col-span-1">
              <StaticImage objectFit="contain" src='../images/client-logos/yes-zee.png' className="h-10" alt='Yes Zee'  />
            </div>
            <div className="col-span-1 hidden items-center sm:flex justify-between lg:col-span-1">
              <StaticImage objectFit="contain" src='../images/client-logos/montura.png' className="h-6" alt='Montura'  />
            </div>
            <div className="col-span-1 hidden items-center xl:flex justify-between lg:col-span-1">
              <StaticImage objectFit="contain" src='../images/client-logos/jeckerson.png' className="h-8" alt='Jeckerson' />
            </div>
            <div className="col-span-1 hidden items-center xl:flex justify-between lg:col-span-1">
              <StaticImage objectFit="contain" src='../images/client-logos/esercito.png' className="h-10" alt='Esercito'  />
            </div>
            <div className="col-span-1 hidden items-center xl:flex justify-between lg:col-span-1">
              <StaticImage objectFit="contain" src='../images/client-logos/facis.png' className="h-8" alt='Facis'  />
            </div>
          </div>
        </div>
      </div>
    )
  }