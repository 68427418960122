import React from 'react'
import { ArrowNarrowDownIcon } from '@heroicons/react/outline'
import ClientLogos from './clientLogos'

import { eventData } from './eventData'
import { StaticImage } from 'gatsby-plugin-image'
import EventBanner from './eventBanner'


export default function Hero({scrollToRef}) {
  return (
    <div  className="relative overflow-hidden grid grid-cols-6 -mt-24 ">
      <div className="col-span-6 pb-4 lg:col-span-4 ">
        <main className="pt-6 lg:pt-72 h-full px-4 sm:px-8">
          <div className="lg:grid lg:grid-cols-12 h-full">
            <div className="relative lg:col-span-9 lg:col-start-3 flex flex-col justify-between h-full">
              <div>
                {eventData.eventState ? <EventBanner />  : null}
                <h1>
                  <span className="mt-3 sm:mt-6 block text-4xl tracking-tight font-extrabold sm:text-5xl xl:text-6xl">
                    <span className="block text-gray-900">A cut above the rest.</span>
                  </span>
                </h1>
                <p className="mt-3 max-w-2xl text-base text-gray-700 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">
                  We are Sara Fashionwear, a 360° clothing manufacturer maintaining top-notch European standards and “Made in Italy” product quality.
                </p>
                <div className='py-7 lg:pt-10 lg:pb-5 cursor-pointer group w-fit' onClick={() => scrollToRef()}> 
                  <button
                    className="inline cursor-pointer animate-bounce h-9 w-9 bg-transparent border border-gray-900 rounded-full p-1 text-base font-medium text-gray-900 group-hover:text-white group-hover:bg-gray-900"
                  >
                    <ArrowNarrowDownIcon className='h-5 mx-auto '/>
                  </button>
                  <span className='ml-3 align-top text-lg lg:text-xl font-medium'>Explore Sara Fashionwear</span>
                </div>
              </div>
              <div className='mt-8 sm:mt-12 md:mt-20 lg:mt-24 xl:mt-30 xl:pt-4'>
                <ClientLogos />
              </div>
            </div>
          </div>
        </main>
      </div>
      <div className='order-first lg:order-last h-80 lg:h-full col-span-6 lg:col-span-2 relative '>
        <div className="relative w-full h-full ">
          <div className='absolute w-full h-full z-20 bg-gradient-to-b from-white lg:via-transparent to-transparent border-b lg:border-b-0 lg:border-l border-gray-200' />
          <StaticImage
            className="absolute z-10 inset-0 w-full h-full object-cover"
            src="../images/hero.jpg"
            alt=""
            objectFit='cover'
            objectPosition='top right'
          />
        </div>
      </div>
    </div>
  )
}