import React from 'react'
import { StaticImage } from "gatsby-plugin-image"

export default function PageTeaser() {
    return (
      <div>
        <div className="pt-32 overflow-hidden sm:pt-14">
          <div className="bg-gray-50">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
              <div className="relative pt-48 pb-16 sm:pb-24">
                <div>
                  <h2 id="sale-heading" className="text-4xl font-extrabold tracking-tight text-gray-900 lg:text-5xl">
                    Quality never goes
                    <br />
                    out of style.
                  </h2>
                  <div className="mt-6 text-base pt-1">
                    <a href="/products" className="font-semibold text-gray-900 bg-primary-300 hover:bg-primary-500 px-2 py-1 rounded-sm">
                      Explore our products<span aria-hidden="true"> &rarr;</span>
                    </a>
                  </div>
                </div>
  
                <div className="absolute -top-32 left-1/2 transform -translate-x-1/2 sm:top-6 sm:translate-x-0">
                  <div className="ml-24 flex space-x-6 min-w-max sm:ml-3 lg:space-x-8">
                    <div className="flex space-x-6 sm:flex-col sm:space-x-0 sm:space-y-6 lg:space-y-8">
                      <div className="flex-shrink-0">
                        <StaticImage
                          className="h-64 w-64 rounded-md object-cover md:h-72 md:w-72"
                          src="../images/product-teaser/1.jpg"
                          alt="Model teaser image"
                        />
                      </div>
  
                      <div className="mt-6 flex-shrink-0 sm:mt-0">
                        <StaticImage
                          className="h-64 w-64 rounded-md object-cover md:h-72 md:w-72"
                          src="../images/product-teaser/2.jpg"
                          alt="Model teaser image"
                        />
                      </div>
                    </div>
                    <div className="flex space-x-6 sm:-mt-20 sm:flex-col sm:space-x-0 sm:space-y-6 lg:space-y-8">
                      <div className="flex-shrink-0">
                        <StaticImage
                          className="h-64 w-64 rounded-md object-cover md:h-72 md:w-72"
                          src="../images/product-teaser/3.jpg"
                          alt="Model teaser image"
                        />
                      </div>
                      <div className="mt-6 flex-shrink-0 sm:mt-0">
                        <StaticImage
                          className="h-64 w-64 rounded-md object-cover md:h-72 md:w-72"
                          src="../images/product-teaser/4.jpg"
                          alt="Model teaser image"
                        />
                      </div>
                    </div>
                    <div className="flex space-x-6 sm:flex-col sm:space-x-0 sm:space-y-6 lg:space-y-8">
                      <div className="flex-shrink-0">
                        <StaticImage
                          className="h-64 w-64 rounded-md object-cover md:h-72 md:w-72"
                          src="../images/product-teaser/5.jpg"
                          alt="Model teaser image"
                          objectPosition='top'
                        />
                      </div>
                      <div className="mt-6 flex-shrink-0 sm:mt-0">
                        <StaticImage
                          className="h-64 w-64 rounded-md object-cover md:h-72 md:w-72"
                          src="../images/product-teaser/6.jpg"
                          alt="Model teaser image"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }