import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import HeroVideo from './HeroVideo';

export default function Usps({myRef, perks}) {
  return (
    <div className="py-16 bg-gray-50 overflow-hidden lg:py-24" ref={myRef}>
    <HeroVideo title={"Sara Fashionwear Company Video"} src={"https://player.vimeo.com/video/670849720?h=cfcfc4cee9&color=ffffff&title=0&byline=0&portrait=0responsive=true"}/>
      <div className="relative max-w-xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-7xl">
        <div className="relative hidden lg:block" >
          <h2 className="text-center text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
            The Sara Fashionwear way
          </h2>
          <div className="mt-4 max-w-3xl mx-auto text-center text-xl text-gray-700">
              <p>We developed the perfect formula for strong business relationships in the field. </p>
              <p>Our secret? We put <b>you</b> first.</p>
          </div>
        </div>
        <div className="relative mt-12 lg:mt-24 lg:grid lg:grid-cols-2 lg:gap-8 lg:items-center">
          <div className="relative">
            <h3 className="text-2xl font-extrabold text-gray-900 tracking-tight sm:text-3xl">
              One-stop quality apparel
            </h3>
            <p className="mt-3 text-lg text-gray-700">
            We provide a wide selection of knitwear products, maintaining our signature quality across the product line, with strict measures and unparalleled consistency. The quality leap begins with the right supplier and your most valued customers will recognize it.
            </p>
            <dl className="mt-10 space-y-10">
                {perks.slice(0,2).map((perk) => (
                <div key={perk.name} className="sm:flex">
                    <div className="sm:flex-shrink-0">
                    <div className="flow-root">
                        {perk.image}
                    </div>
                    </div>
                    <div className="mt-3 sm:mt-0 sm:ml-3">
                    <h3 className="text-sm font-medium text-gray-900">{perk.name}</h3>
                    <p className="mt-2 text-sm text-gray-700">{perk.description}</p>
                    </div>
                </div>
                ))}
            </dl>
          </div>
          <div className="hidden lg:block max-w-xl mx-auto rounded-sm overflow-hidden mt-10 relative lg:mt-0" aria-hidden="true">
            <StaticImage
              className="relative w-full"
              src="../images/usp-1.jpg"
              alt=""
            />
          </div>
        </div>
        <div className="relative mt-12 sm:mt-16 lg:mt-24">
          <div className="lg:grid lg:grid-flow-row-dense lg:grid-cols-2 lg:gap-8 lg:items-center">
            <div className="lg:col-start-2">
              <h3 className="text-2xl font-extrabold text-gray-900 tracking-tight sm:text-3xl">Buyer-first approach</h3>
              <p className="mt-3 text-lg text-gray-700">
              We employ an innovative business strategy that centers around making our buyers' decision-making process and communication as smooth as possible. 
              </p>
              <dl className="mt-10 space-y-10">
                {perks.slice(2,4).map((perk) => (
                    <div key={perk.name} className="sm:flex">
                        <div className="sm:flex-shrink-0">
                        <div className="flow-root">
                            {perk.image}
                        </div>
                        </div>
                        <div className="mt-3 sm:mt-0 sm:ml-3">
                        <h3 className="text-sm font-medium text-gray-900">{perk.name}</h3>
                        <p className="mt-2 text-sm text-gray-700">{perk.description}</p>
                        </div>
                    </div>
                    ))}
              </dl>
            </div>
            <div className="hidden lg:block max-w-xl mx-auto rounded-sm overflow-hidden mt-10 relative lg:mt-0">
              <StaticImage
                className="relative w-full"
                objectFit='cover'
                src="../images/usp-2.jpg"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}