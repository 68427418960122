import React from "react"

export default function HeroVideo({ title, src, videoSrcURL, videoTitle, ...props }) {
  return (
    <div className="flex justify-center lg:pb-12 px-4">
      <div className="w-full md:max-w-4xl lg:max-w-6xl">
        <div className="rounded-sm relative w-full max-w-7xl mx-auto overflow-hidden" style={{ 'paddingTop': '56.25%' }}>
          <iframe
            className="absolute top-0 h-full w-full"
            src={src}
            width="1024"
            height="576"
            title={title}
            frameBorder="0"
            allow="autoplay; fullscreen; picture-in-picture"
            allowFullScreen>
          </iframe>
        </div>
      </div>
    </div>
  )
}