import React from 'react';
import { ChevronRightIcon } from '@heroicons/react/solid'

import { eventData } from './eventData'

export default function EventBanner() {
    return (
        <div className='absolute -mt-9 pb-4 z-50'>
            <a href={eventData.eventURL} target='_blank' rel="noreferrer" className="inline-flex items-center text-gray-900 bg-primary-500 rounded-sm p-1 sm:text-base lg:text-sm xl:text-base hover:bg-primary-700">
                <span className="px-3 py-0.5 text-black text-xs font-semibold leading-5 uppercase tracking-wide bg-white rounded-sm">
                    {eventData.eventCTA}
                </span>
                <span className="ml-2 text-sm">{eventData.eventDescription}</span>
                <ChevronRightIcon className="ml-1 w-5 h-5 text-gray-900" aria-hidden="true" />
            </a>
        </div>
    )
}
